import { faCircle } from "@fortawesome/free-solid-svg-icons/faCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import { Link } from "react-router-dom";

import BasicTooltip from "components/Common/Tooltip/BasicTooltip";

import EntityImage from "../EntityImage";

import { getNetworkUrlByEntity } from "utils/entity/getNetworkUrl";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  authorAndNetworkContainer: {
    display: "flex",
    gap: 5,
    marginTop: -5,
  },
  networkContainer: {
    display: "flex",
    gap: 5,
    alignItems: "center",
    color: "var(--color-neutral-d5)",
  },
  label: {
    ...gStyles.avalonBold,
    fontSize: "0.813rem",
    margin: 0,
    color: "var(--color-neutral-d5)",

    maxWidth: 100,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",

    [ScreenSizes.smAndAbove]: {
      maxWidth: 135,
    },

    [ScreenSizes.xlAndAbove]: {
      maxWidth: 200,
    },
  },
  network: {
    ...gStyles.avalonBold,
    fontSize: "0.813rem",
    margin: 0,
    color: "var(--color-neutral-d5)",

    maxWidth: 100,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",

    [ScreenSizes.smAndAbove]: {
      maxWidth: 135,
    },

    [ScreenSizes.xlAndAbove]: {
      maxWidth: 200,
    },

    ":hover": {
      color: colours.primary,
      cursor: "pointer",
    },
  },
  flex: {
    display: "flex",
    alignItems: "center",
    gap: 5,
  },
  circle: {
    fontSize: 5,
    margin: "auto 0",
  },
};

const imagestyles = {
  entityImage: {
    background: null,
  },
};

export default function PodcastCardNetworkAndAuthor(props) {
  const { styles } = useStyles(baseStyles, props);

  const { podcast } = props;

  const author = podcast?.get("itunes_owner_name");
  const networks = podcast?.get("networks");

  return (
    <div className={css(styles.authorAndNetworkContainer)}>
      <p className={css(styles.label)}>{author}</p>
      {networks?.map((network) => {
        const networkLink = getNetworkUrlByEntity(network);
        const imageUrl = network?.get("image_url");
        const networkTitle = network?.get("title");

        return (
          <div className={css(styles.networkContainer)} key={networkTitle}>
            <span className={css(styles.circle)}>
              <FontAwesomeIcon icon={faCircle} />
            </span>
            <BasicTooltip
              renderTooltip={() =>
                `This podcast is a part of ${networkTitle} network.`
              }
            >
              {(tooltipProps) => (
                <Link
                  {...tooltipProps}
                  data-testid="networkTitle"
                  to={networkLink}
                  className={css(styles.flex)}
                >
                  <EntityImage
                    entity={network}
                    entity_type="network"
                    image_url={imageUrl}
                    size={14}
                    borderRadius={3}
                    disableLink
                    styles={imagestyles}
                  />
                  <p className={css(styles.network)}>{networkTitle}</p>
                </Link>
              )}
            </BasicTooltip>
          </div>
        );
      })}
    </div>
  );
}
